import { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import { PricingModel } from '../../../../webmodule-common/pricing/pricing-model';
import { LitBaseModal } from '../../../../webmodule-common/other/ui/modal/modal-factory-lit';
import { TemplateResult } from 'lit';
import { buttonsSaveCancel } from '../../../../webmodule-common/other/ui/modal-confirmation';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { FormInputAssistant } from '../../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { DataTracker, FieldType } from '../../../../webmodule-common/other/ui/databinding/data-tracker';
import { DataBinding } from '../../../../webmodule-common/other/ui/databinding/databinding';
import { checkValidations } from '../../../../webmodule-common/other/ui/data-entry-screen-helpers';

export class QuotePricingmodelAdjustmentDialog extends LitBaseModal {
  public modalResult = false;
  private quoteManager: FranchiseeQuoteContainerManager;
  private dataTracker: DataTracker;
  private data: { value: number } = { value: 0 };

  constructor(quoteManager: FranchiseeQuoteContainerManager) {
    super();
    this.quoteManager = quoteManager;

    this.data.value = this.quoteManager.container.quotePrice?.percentMarginOrMarkup ?? 0;
    this.dataTracker = new DataTracker(new DataBinding(this.ui));
    this.dataTracker.addObjectBinding(() => this.data, 'value', 'value', FieldType.string);
  }

  get isFooterVisible(): boolean {
    return true;
  }

  private get pricingModel(): PricingModel {
    return this.quoteManager.pricingModel;
  }

  public static async Execute(quoteManager: FranchiseeQuoteContainerManager): Promise<boolean> {
    const dialog = new QuotePricingmodelAdjustmentDialog(quoteManager);
    await dialog.showModal();
    return dialog.modalResult;
  }

  title(): string | TemplateResult {
    //backwards compatible with ModalDialog
    return tlang`${'ref:quote-margin-markup-title'}%%franchisee%% %%quote%% Level ${this.pricingModel.calculationLabel} Adjustment`;
  }

  bodyTemplate(): TemplateResult {
    const forms = new FormInputAssistant(this.dataTracker, false, true);
    return forms.int('value', this.pricingModel.label, this.pricingModel.editorMin, this.pricingModel.editorMax);
  }

  footerTemplate(): TemplateResult | null | undefined {
    const buttons = buttonsSaveCancel();
    return this.createConfirmCancelButtons(buttons.ok, () => this.saveAndClose(), buttons.cancel);
  }

  private async saveAndClose() {
    const value = this.data.value;
    const validations = this.pricingModel.getValueValidations(value, undefined, this.pricingModel.calculationLabel);

    const p = this.quoteManager.container.quotePrice!;
    if (!(await checkValidations(validations.errors))) return;
    if (p.percentMarginOrMarkup === value) return;
    p.percentMarginOrMarkup = value;
    await this.quoteManager.saveQuote(false);

    this.modalResult = true;
    this.hideModal();
  }
}
