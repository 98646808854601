import { dealerVerifyUser, performPageLoadLoginVerifications } from '../api/dealer-pageload-verifications';
import { userDataStore } from './common/current-user-data-store';
import {
  clearCurrentUserFromSession,
  saveCurrentUserIntoSession,
  verifySession
} from '../../webmodule-common/other/user-session-verifier';
import { getCurrentUser, setAfterApplyUserEvent } from '../../webmodule-common/other/api/current-user';
import { getApi, setApiInjector } from '../../webmodule-common/other/api/api-injector';
import type { ConnectedState } from '../api/dealer-api-interface-franchisee';
import { DevelopmentError, showDevelopmentError } from '../../webmodule-common/other/development-error';
import { getQuoteSuppliers, QuoteSupplier, setQuoteSupplierProviderCallback } from '../quotes/quote-service';
import { currentUserClaims } from '../../webmodule-common/other/currentuser-claims';
import { displaySupplierTACNotApprovedMsg, GlobalSupplierNotification } from '../v6config/supplier-services';
import {
  addV6BootLoaderEvent,
  v6BootLoader,
  v6bootloaderStale,
  v6Config,
  v6ConfigActiveVersionStr
} from '../v6config/v6config';
import { applyDebugInformation } from './debug';
import { registerComponents } from '../../webmodule-common/components/src/components/register-components';
import { registerComponentsLocal } from '../../webmodule-common/other/ui/templatecontrols/component-registry';
import { setSupplierInformationCache } from '../quotes/data/quoteSupplierProvider';
import { registerLangFile, tlang } from '../../webmodule-common/other/language/lang';
import {
  DealerApiCommunications,
  setDealerTokenProvider
} from '../../webmodule-common/other/api/dealer-api-communications';
import { getUserLock } from '../../webmodule-common/other/api/optimistic-user-lock';
import {
  ServiceResponseInvalid,
  ServiceResponseType
} from '../../webmodule-common/interop/interfaces/service_response';
import type { EventSnippet } from '../../webmodule-common/interop/types/misc';
import { displayAllError } from '../../webmodule-common/other/ui/modal-errorhandler';
import { setErrorDialogEventHandler, showError } from '../../webmodule-common/other/ui/show-error';
import {
  setQuoteProviderDataFactory,
  setQuoteProviderDataValidateAndUpgradeEvent
} from '../quotes/data/quote-provider-data';
import {
  createFranchiseeQuoteProviderData,
  validateAndUpdateFranchiseeQuoteProviderData
} from './quotes/data/franchisee-quote-provider-data';
import { addOrReplaceNFRCCalculation } from '../nfrc/nfrc-calculation';
import { NFRCStandardv1Calculation } from '../nfrc/nfrc-standard-v1';
import { getApiFactory, setApiFactory } from '../api/api-injector';
import { QuoteApiImpl } from '../api/quote-api-impl';
import { BlobApiImpl } from '../api/blob-api-impl';
import { DealerClientApi } from '../api/client-api-implementation';
import { ProjectApiImplementation } from '../api/project-api-implementation';
import { DealerFranchiseeApi } from '../api/franchisee-api-implementation';
import { PaymentProfileApiImplementation } from '../api/payment-profile-api-implementation';
import { PurchaseOrderApiImplementation } from '../api/purchase-order-api-implementation';
import { SupplierApiImplementation } from '../api/supplier-api-implementation';
import { DealerClientAgentApi } from '../api/clientagent-api-implementation';
import { UserApiImplementation } from '../api/user-api-implementation';
import { responseHandler } from '../api/api-response-handler';
import { setCacheRegistry } from './cache-impl/cache-registry';
import { ClientCache } from './cache-impl/client-cache';
import { QuoteItemConversationCache } from './cache-impl/quoteitemconversation-cache';
import { ContactReferenceCache } from './cache-impl/contact-reference-cache';
import { ClientPrimaryContactCache } from './cache-impl/client-primarycontact-cache';
import { PaymentProfileCache } from './cache-impl/payment-profile-cache';
import { ProjectCache, ProjectPermanentDocumentCache } from './cache-impl/project-cache';
import { QuoteCache } from './cache-impl/quote-cache';
import { ResourcesCache } from './cache-impl/resources-cache';
import { UserProfileCache } from './cache-impl/user-profile-cache';
import { PurchaseOrderCache } from './cache-impl/purchase-order-cache';
import { ProjectResourceLinkCache } from './cache-impl/project-resource-link';
import { QuoteStateChangeReasonCache } from './cache-impl/quote-state-change-reason-cache';
import { ProjectStateChangeReasonCache } from './cache-impl/project-state-change-reason-cache';
import { PurchaseOrderStateChangeReasonCache } from './cache-impl/purchase-order-state-change-reason-cache';
import { emptyGuid } from '../../webmodule-common/other/api/guid';
import { runGlobalInit } from '../../webmodule-common/other/app-global-init';
import { stagedExecution, WMEventSourceClient } from '../../webmodule-common/other/general/event-source-client';
import { GlobalNotificationManager } from '../../webmodule-common/other/ui/icons/icon-notification-signal';
import { WMEventSource } from '../api/event-source';
import { EventCancellation } from '../../webmodule-common/other/general/staged-event';
import { information } from '../../webmodule-common/other/ui/modal-option';
import { SupplierTermsAndConditionsModal } from './supplier/terms-and-conditions-modal';
import { registerTechnicalDictionary } from '../../webmodule-common/other/language/technical-dictionary';
import { pageReload } from '../../webmodule-common/other/ui/resource-resolver';
import { bindApplicationFeatures } from '../../webmodule-common/other/bind-application-features';

function reload() {
  //if this flag exists then we have forced a logoff as part of a PAT login process, so we do not want to redirect or do anything
  if (sessionStorage.getItem('dealer-pat-login')) return;

  // Take us to the home page. this will clear any validations
  // or other issues
  // the home page has code to trigger login if required and
  // page reloading
  window.location.href = '/login';
}

async function processSupplierTAC(suppliers: QuoteSupplier[]) {
  for (let i = 0; i < suppliers.length; i++) {
    const supplier = suppliers[i];
    const tacApproved = userDataStore.supplierTACApproved(supplier.supplierId);
    if (!tacApproved) {
      const claims = currentUserClaims();
      if (claims.isAgent || claims.isCynclyStaff || claims.trueClaim('non-tenant-user'))
        await displaySupplierTACNotApprovedMsg(supplier);
      else await approveTACforSupplier(supplier);
    }
  }
}

export let _v6SupplierVersion = '';
export let _v6FrameConfigVersion = '';
export let _v6FrameConfigApiVersion = '';

function applyQuoteSupplierProviderCallback() {
  setQuoteSupplierProviderCallback(async (refreshList: boolean) => {
    //BOOTLOADER MUST BE RUN BEFORE THIS FUNCTION

    const v6Suppliers = (await v6Config().suppliers(refreshList)) ?? [];

    _v6SupplierVersion = v6Suppliers.length === 1 ? v6Suppliers[0].version : '';
    _v6FrameConfigVersion = v6ConfigActiveVersionStr();
    _v6FrameConfigApiVersion = globalThis.dealerConfiguration.v6apiBuildNumberDisplay;

    const results = v6Suppliers.map(s => {
      const qs: QuoteSupplier = {
        supplierId: s.id,
        description: s.description,
        online: s.online,
        offlineCause: s.offlineCause
      };
      return qs;
    });
    results.forEach(si => {
      GlobalSupplierNotification.getInstance(si.supplierId).setState(si);
    });
    return results;
    /*
                             else {
                              if (_suppliers !== null && !refreshList) return _suppliers;
                              _suppliers = (await getQuoteSupplierFromApi()) ?? [];
                              _suppliers.forEach(si => {
                                GlobalSupplierNotification.getInstance(si.supplierId).setState(si);
                              });
                              return _suppliers;
                            }
                            
                             */
  });
}

const bootloaderStaleEvent = (gsn: GlobalSupplierNotification) => {
  if (!gsn.online) v6bootloaderStale();
  else
    v6BootLoader().then(value => {
      console.log(`V6Bootloader: ${value}`);
    });
};
const afterV6BootLoader = async (): Promise<void> => {
  if (!userDataStore.loaded) throw new DevelopmentError('cant perform bootloading without user data');

  applyQuoteSupplierProviderCallback();

  const suppliers = await getQuoteSuppliers(!(await userDataStore.setV6OverrideKey()));
  suppliers.forEach(s => {
    GlobalSupplierNotification.getInstance(s.supplierId).addEventListenter(bootloaderStaleEvent);
  });

  await processSupplierTAC(suppliers);
  currentSupplierNames = suppliers.map(x => `[${x.description}]`).join(', ');
};

export let currentSupplierNames = '';

async function dealerPrepUserData(updateNote: (msg: string) => void) {
  try {
    updateNote('Initialize Frame Configuration Service');
    //bootloader has already been run by the currentuserdatastore
    if (!(await v6BootLoader())) {
      await information(
        tlang`The %%frame%% configuration system could not be loaded and the software cannot run. please contact admin if this continues.
      refreshing page now
      `,
        tlang`%%frame%% configuration services offline`
      );
      pageReload();
    }
    //we want to enforce that the system the user connects to is fully up to date. this is a
    //simple call to the v6 systems to check their version, and will ensure the system resets info
    //as needed. This is also required for the quotes page, so we know if we are a multi supplier view or not
    updateNote('Fetching Supplier Information');
  } catch (e) {
    console.log('could not load suppliers' + (e as any).toString());
  }
}

function dealerBuildApplicationBindings() {
  registerTechnicalDictionary(globalThis.dealerConfiguration.dictItems);
  registerLangFile(globalThis.dealerConfiguration.langFile);
  applyDebugInformation();
  registerComponents();
  registerComponentsLocal();
  bindApplicationFeatures();
  setAfterApplyUserEvent(connectUserAfterLogin);
  registerEventSourceClients();
  setSupplierInformationCache({
    getSupplierDisplayName: async (supplierId: string) => {
      const suppliers = await getQuoteSuppliers(false);
      return suppliers.find(x => x.supplierId === supplierId)?.description ?? tlang`%%supplier%%`;
    }
  });
  setDealerTokenProvider(() => getUserLock());
  const errorDialogEventHandler = async (item: ServiceResponseInvalid | Error, title: EventSnippet) =>
    await displayAllError(title, item);
  setErrorDialogEventHandler(errorDialogEventHandler);

  //Bind data provider information inot the generic
  setQuoteProviderDataFactory(createFranchiseeQuoteProviderData);
  setQuoteProviderDataValidateAndUpgradeEvent(validateAndUpdateFranchiseeQuoteProviderData);
  addOrReplaceNFRCCalculation(new NFRCStandardv1Calculation());
  setApiFactory({
    quote: () => new QuoteApiImpl(getApi()),
    blob: () => new BlobApiImpl(getApi()),
    client: () => new DealerClientApi(getApi()),
    project: () => new ProjectApiImplementation(getApi()),
    franchisee: () => new DealerFranchiseeApi(getApi()),
    paymentProfile: () => new PaymentProfileApiImplementation(getApi()),
    purchaseOrder: () => new PurchaseOrderApiImplementation(getApi()),
    supplier: () => new SupplierApiImplementation(getApi()),
    clientAgent: () => new DealerClientAgentApi(getApi()),
    user: () => new UserApiImplementation(getApi())
  });

  let _commSingleton: DealerApiCommunications | undefined;
  const apiInjecterEvent = () => {
    if (!_commSingleton)
      _commSingleton = new DealerApiCommunications('', responseHandler, () => {
        //Redirect to home page, next query will force a login to occur
        window.location.href = '/login';
      });
    return _commSingleton;
  };

  //Dependency inject an api for the entire application
  setApiInjector(apiInjecterEvent);
  setCacheRegistry(() => {
    const api = getApi();
    return {
      client: new ClientCache(api),
      quoteItemConversation: new QuoteItemConversationCache(api),
      contact: new ContactReferenceCache(api),
      primaryContact: new ClientPrimaryContactCache(api),
      paymentProfile: new PaymentProfileCache(api),
      project: new ProjectCache(api),
      projectPermanentDocuments: new ProjectPermanentDocumentCache(api),
      quote: new QuoteCache(api),
      resource: new ResourcesCache(api),
      userProfile: new UserProfileCache(api),
      purchaseOrder: new PurchaseOrderCache(api),
      projectResourceLink: new ProjectResourceLinkCache(api),
      quoteStateReason: new QuoteStateChangeReasonCache(api),
      projectStateReason: new ProjectStateChangeReasonCache(api),
      purchaseOrderStateReason: new PurchaseOrderStateChangeReasonCache(api)
    };
  });

  setQuoteSupplierProviderCallback(async (_refreshList: boolean) => {
    return [
      {
        supplierId: emptyGuid,
        description: tlang`Loading`,
        online: false
      }
    ];
  });

  window.addEventListener('unhandledrejection', event => {
    if (event.reason.message.includes('Vaadin')) {
      return;
    }
    console.error(event.reason.stack);
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    showError(
      {
        responseType: ServiceResponseType.Error,
        responseTypeCaption: tlang`unhandled error`,
        responseError: {
          message: event.reason.message,
          stackTrace: event.reason.stackTrace
        }
      },
      () => tlang`Unhandled Error inside a promise occurred`
    );
  });
  addV6BootLoaderEvent(afterV6BootLoader);

  runGlobalInit();
}

function registerEventSourceClients() {
  //configure main event source.
  WMEventSourceClient.getInstance(undefined, {
    onlineCallback: value => {
      GlobalNotificationManager.getInstance().online = value;
      if (globalThis.dealerConfiguration.apiHost === globalThis.dealerConfiguration.v6apiHost)
        GlobalNotificationManager.getInstance('v6config').online = value;
    },
    afterOnlineEvent: () => {
      WMEventSource.afterConnect();
    },
    url: globalThis.dealerConfiguration.apiHost
  });

  const supplierStatusEvent = stagedExecution({
    event: () => {
      //if we are getting this event, something was rebooted.
      v6bootloaderStale();
      globalThis.v6config = globalThis.v6config ?? {};
      globalThis.v6config.forceLoad = true;
      getQuoteSuppliers(true).finally(() => {
        globalThis.v6config.forceLoad = false;
      });
    },
    cancelToken: new EventCancellation(),
    testInterval: 250,
    threshold: 1000
  });
  //add an event listener to v6 status events
  WMEventSourceClient.getInstance().addEventListener(WMEventSource.v6configServiceStatus, supplierStatusEvent);

  //if we are running a seperate service for v6 events bind in a 2nd listner
  if (globalThis.dealerConfiguration.apiHost !== globalThis.dealerConfiguration.v6apiHost) {
    //running a seperate server so lets do a passthrough event
    WMEventSourceClient.getInstance('v6config', {
      onlineCallback: value => {
        GlobalNotificationManager.getInstance('v6config').online = value;
      },
      afterOnlineEvent: () => {
        //WMEventSource.afterConnect();
      },
      url: globalThis.dealerConfiguration.v6apiHost
    }).addEventListener('', (data, eventName) => {
      //we dont want seperate listeners.. we will pass everything through to the main system
      WMEventSourceClient.pushEventToDefault(eventName, data);
    });
  }
}

async function approveTACforSupplier(supplier: QuoteSupplier) {
  const r = await getApiFactory().supplier().getSupplierTAC({ supplierId: supplier.supplierId });
  if (!r) {
    await information(tlang`The %%supplier%% (${supplier.description}) Terms and Conditions cannot be retrieved at this time. Please try refreshing this page. 
        Contact support if no progress is made.`);
    return false;
  }
  const modal = new SupplierTermsAndConditionsModal(supplier, r?.supplierTermsAndConditionsOfUse);
  await modal.showModal();
  return modal.ok;
}

if (!performPageLoadLoginVerifications) console.log('missing page loader');

/**
 * perform actions here unique to after an actual login, not related to normal initialization of a user
 */
async function connectUserAfterLogin() {
  const reset = async () => {
    userDataStore.clear();

    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    reload();
  };
  const user = getCurrentUser();
  if (user !== null) {
    if (!(await verifySession())) {
      await reset();
      return;
    }

    //DO NOT MAKE ANY CALLS THAT NEED AUTHORIZATION BEFORE CONNECTING THE USER
    //Make any api calls or anything else here that are necessary to be used for the current user
    try {
      const state = await getApi().post<ConnectedState>('api/Franchisee/ConnectUser', {});
      if (!state?.connected) {
        await information(
          tlang`Your login details are valid, but the server refused to accept your login at this time. please try again later.`,
          tlang`Login Rejected`
        );
        await reset();
        return;
      }

      //this will update the user claims security before we rebind the router.
      await userDataStore.loadCoreDetailsAfterLogin();
      saveCurrentUserIntoSession();
    } catch (e) {
      await showDevelopmentError(e as Error);
      await reset();
    }
  } else {
    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    userDataStore.clear();
    reload();
    //we always want to login again
  }
}

let _appStartupEvents: Promise<void> | undefined;

export function getApplicationStartupEvents() {
  if (_appStartupEvents === undefined) {
    _appStartupEvents = applicationStartupEvents();
  }
  return _appStartupEvents;
}

globalThis.getApplicationStartupEvents = getApplicationStartupEvents;

async function applicationStartupEvents() {
  const log = (_msg: string) => {
    //console.log('STARTUP: ' + msg);
  };
  dealerBuildApplicationBindings();
  if (!(await dealerVerifyUser(log))) {
    window.location.reload();
  }
  await dealerPrepUserData(log);
}
