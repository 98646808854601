// eslint-disable-next-line import/named
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';
import { FormInputSelectValue } from '../../FormInputView';
import { html, TemplateResult } from 'lit';

import { when } from 'lit/directives/when.js';
import { InputEvent } from './form-input';

import { map } from 'lit/directives/map.js';

export function formSelect(
  fieldName: string,
  placeHolder: string,
  title: string | undefined,
  values: string | FormInputSelectValue[],
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  readOnly = false,
  required = false,
  onChangeEvent?: InputEvent,
  immediateBindingUpdate?: boolean
): TemplateResult {
  const internalOptions = values ?? [];

  const optionsArray: FormInputSelectValue[] =
    typeof internalOptions === 'string' ? JSON.parse(internalOptions) : internalOptions;

  const eventOnChanged = (e: Event) => {
    if (immediateBindingUpdate ?? true) {
      dataTracker.getBinder(fieldName)?.applyChangeToValue();
    }
    onChangeEvent?.(e);
  };
  const value = dataTracker.getObjectValue(fieldName)?.toString() ?? '';
  return html` <webmodule-select
    class="webmodule-control-left-label"
    id=${dataBinding.field(fieldName)}
    placeholder=${placeHolder}
    ?disabled=${readOnly}
    ?required=${required}
    .value=${value ?? ''}
    size="small"
    @webmodule-change=${eventOnChanged}
    hoist
  >
    ${when(title, () => html` <span slot="label"> ${title} </span> `)}
    ${map(optionsArray, x => html` <webmodule-option value="${x.value}">${x.text}</webmodule-option>`)}
  </webmodule-select>`;
}
