import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';

export class InputBoolean extends LitElement {
  @property() checked = false;
  @property() readonly = false;
  @property() disabled = false;
  @property() label = '';

  render() {
    const clickEvent = (e: Event) => {
      e.stopPropagation();
      e.preventDefault();
      if (this.readonly || this.disabled) return;
      this.checked = !this.checked;
      this.click();
    };
    const textColor = this.disabled || !this.checked ? 'text-secondary' : 'text-primary';
    return this.checked
      ? html`<span @click=${clickEvent}>
          <i class="fa-solid  fa-circle-check ${textColor}"></i>
          ${this.label}
        </span>`
      : html`<span @click=${clickEvent}>
          <i class="fa-regular  fa-circle ${textColor}"></i>
          ${this.label}
        </span>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
